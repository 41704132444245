// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-draft-index-jsx": () => import("./../../../src/pages/blog/draft/index.jsx" /* webpackChunkName: "component---src-pages-blog-draft-index-jsx" */),
  "component---src-pages-blog-microcms-blog-slug-jsx": () => import("./../../../src/pages/blog/{microcmsBlog.slug}.jsx" /* webpackChunkName: "component---src-pages-blog-microcms-blog-slug-jsx" */),
  "component---src-template-blog-page-jsx": () => import("./../../../src/template/blog-page.jsx" /* webpackChunkName: "component---src-template-blog-page-jsx" */),
  "component---src-template-categories-jsx": () => import("./../../../src/template/categories.jsx" /* webpackChunkName: "component---src-template-categories-jsx" */),
  "component---src-template-tags-jsx": () => import("./../../../src/template/tags.jsx" /* webpackChunkName: "component---src-template-tags-jsx" */)
}

